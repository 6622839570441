.backgroundImageIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.transparancyDiv1 {
  background-color: var(--black);
  opacity: 0.2;
}
.transparancyDiv,
.transparancyDiv1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.letsGoDiv {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-cy);
  color: var(--black);
  text-align: center;
  display: inline-block;
}
.buttonPrimary {
  cursor: pointer;
  border: 0;
  padding: var(--padding-md) 20px;
  background-color: var(--lemon);
  align-self: stretch;
  border-radius: var(--br-sm);
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.seeHowItWorks {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-cy);
  color: var(--lemon);
  text-align: center;
  display: inline-block;
}
.buttonSecondary {
  cursor: pointer;
  border: 1px solid var(--lemon);
  padding: var(--padding-md) 20px;
  background-color: transparent;
  align-self: stretch;
  border-radius: var(--br-sm);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frameDiv {
  position: absolute;
  width: calc(100% - 32px);
  right: 16px;
  bottom: 24px;
  left: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-md);
}
.helloKiteLover {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 329px;
}
.readyToGetYourKitePlan {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 160%;
  font-weight: 500;
  font-family: var(--font-montserrat);
  color: var(--grey-light);
  display: inline-block;
}
.copyDiv {
  position: absolute;
  top: 240px;
  left: calc(50% - 163.5px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-md);
}
.welcomePageDiv {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  text-align: center;
  font-size: 32px;
  color: var(--lemon);
  font-family: var(--font-cy);
}


.package-building- {
  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    min-height: 690px;
    max-height: 80vh;
    overflow-x: hidden;
    background: rgb(245, 245, 245);
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0,0,0,0.08);
    border: 1px solid rgba(230, 230, 230, 0.1);
    opacity: 1;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  &-container::-webkit-scrollbar {
    display: none;
  }

  &-header {
    position: sticky;
    top: 0;
    z-index: 100;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    padding: 0 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.04);

    &-icons-right-wrapper {
      display: flex;
      width: 15%;
      flex-direction: row;
      justify-content: space-between;
    }

    h2 {
      font-size: 26px;
      text-align: end;
      width: 45%;
    }
  }

  &-info {
    &-container {
      z-index: 2;
      background: rgb(255, 255, 255);
      box-shadow: 0 16px 16px rgba(0, 0, 0, .08);
      border-radius: 8px;
      width: auto;
      padding: 16px;
      height: auto;
    
      h4 {
        padding: 0;
        margin: 0;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 700;
      }

      &-add-remove-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 2rem 1rem 0 0;

        img {
          width: 28px;
          height: 28px;
          margin-left: 1.25rem;
        }
      }

      &-features-selection {
        margin-top: 1.5rem;
      }

      &-package-price {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 2rem 0;
        padding: 1.5rem 0rem 3rem 0rem;
        border-bottom: 1px solid rgba(210, 210, 210, 0.8);

        h3 {
          margin: 0;
          font-size: 24px;
          color: rgb(41, 137, 6);
        }
      }
    }

    &-step {
      display: flex;
      height: 15vh;
      width: 100%;
      margin-bottom: 1.25rem;

      &-left-side-img {
        width: 100px;
        height: 100px;
      }

      div:first-child {
        margin-right: 16px;
      }
      div:last-child {
        height: 90%;
      }
      div:last-child > p:first-child {
        margin-bottom: 16px;
      }

      &-left-side {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .08);
        padding: 12px 12px 4px 12px;
        height: 90%;
        
        p {
          padding: 0;
          margin: 0;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
        }
      }
      &-right-side {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .08);
        padding: 12px 12px 4px 12px;
        width: 70%;
        height: 90%;

        p {
          padding: 0;
          margin: 0;
          font-size: 16px;
          align-items: center;
          display: flex;
          flex-direction: row;
        }
        &-bold-text {
          font-size: 14px !important;
          font-weight: 600;
        }
        &-truck-icon {
          width: 24px;
          height: 24px;
          margin-right: .25rem;
        }
      }
    }
    &-step:not(:first-child) {
      margin-top: 16px;
    }
    &-btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid rgba(0,0,0,0);
      transition: transform 80ms ease-in-out, box-shadow 80ms ease-in-out, border 80ms ease-in-out;
    }
    &-btn:hover {
      transform: translateY(-1px);
    }
    &-btn:active {
      transform: translateY(1px);
    }
  }
}
