.baseDiv,
.iconLemonSqeezer {
  // position: absolute;
  bottom: 0;
  left: 0;
}
.baseDiv {
  width: 100%;
  right: 0;
  border-radius: var(--br-sm);
  background-color: var(--lemon);
  height: 48px;
}
.iconLemonSqeezer {
  height: 100%;
  max-height: 100%;
  width: 28.7px;
}
.requestMyGear {
  position: relative;
  bottom: 20px;
  padding-left: 10px;
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-cy);
  color: var(--black);
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
.nameDiv {
  position: relative;
  height: 100%;
  width: 100%;
}
.submitButton {
  position: relative;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  width: 100%;
  margin-top: 48px;
  // margin-bottom: 16px;
  height: 48px;
  padding: 0px;
}
.div,
.theseProductsWouldRegulary {
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 160%;
  display: inline-block;
}
.div {
  bottom: 2px;
  left: 299px;
  font-size: var(--font-size-base);
  text-decoration: line-through;
  line-height: 16px;
  font-weight: 500;
  color: var(--red);
  text-align: right;
}
.underlineIcon {
  position: absolute;
  width: calc(100% - 279px);
  right: 39px;
  bottom: 4.5px;
  left: 240px;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  opacity: 0.1;
}
.copyRegularCost {
  position: absolute;
  width: calc(100% - 32px);
  right: 16px;
  bottom: 96px;
  left: 16px;
  height: 19px;
  font-size: var(--font-size-sm);
  font-family: var(--font-montserrat);
}
.underlineIcon1 {
  position: absolute;
  width: calc(100% - 187px);
  right: 97px;
  bottom: 8.5px;
  left: 90px;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  opacity: 0.1;
}
.itemsDiv,
.mDiv,
.totalDiv {
  position: absolute;
  line-height: 160%;
  display: inline-block;
}
.totalDiv {
  bottom: 3px;
  left: 0;
  font-weight: 600;
}
.itemsDiv,
.mDiv {
  bottom: 4px;
  left: 38px;
  font-size: var(--font-size-sm);
}
.mDiv {
  bottom: 0;
  left: 246px;
  font-size: var(--font-size-xl);
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-cy);
  text-align: right;
}
.copyTotalDiv {
  position: absolute;
  width: calc(100% - 32px);
  right: 16px;
  bottom: 119px;
  left: 16px;
  height: 38px;
  font-family: var(--font-montserrat);
}
.lineIcon {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 12px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 10px;
  opacity: 0.1;
}
.monthsDiv {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-cy);
  color: var(--black);
  text-align: center;
  display: inline-block;
}
.button1 {
  cursor: pointer;
  border: 1px solid var(--black);
  padding: var(--padding-sm);
  background-color: var(--white);
  position: absolute;
  bottom: 0;
  left: calc(50% - 42.5px);
  border-radius: var(--br-sm);
  box-sizing: border-box;
  width: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sliderDurationDiv {
  position: absolute;
  width: calc(100% - 32px);
  right: 16px;
  bottom: 189px;
  left: 16px;
  height: 35px;
}
.baseDiv1 {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  border-radius: var(--br-sm);
  border: 1px dashed var(--dark-blue);
  box-sizing: border-box;
  height: 40px;
  opacity: 0.3;
}
.iconAdd {
  position: absolute;
  top: 2px;
  left: calc(50% - 28px);
  width: 16px;
  height: 16px;
}
.moreDiv {
  position: absolute;
  top: 0;
  left: calc(50% - 8px);
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
}
.buttonDiv {
  position: absolute;
  top: 10px;
  left: calc(50% - 27.5px);
  width: 56px;
  height: 19px;
  opacity: 0.5;
}
.buttonMoreDiv {
  position: absolute;
  width: calc(100% - 48px);
  top: 352px;
  right: 24px;
  left: 24px;
  height: 40px;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--dark-blue);
}
.imageIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.cOMPDYNALITEDiv,
.straplessFreestyle2023 {
  position: relative;
  line-height: 160%;
  display: inline-block;
}
.cOMPDYNALITEDiv {
  text-transform: uppercase;
  font-weight: 600;
}
.straplessFreestyle2023 {
  font-size: var(--font-size-sm);
  font-family: var(--font-montserrat);
}
.copyDiv {
  position: absolute;
  top: 7px;
  left: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-0);
}
.buttonRemoveIcon {
  position: absolute;
  top: 12px;
  right: 0;
  width: 32px;
  height: 32px;
}
.card3Div {
  position: absolute;
  top: 280px;
  left: 24px;
  width: 327px;
  height: 56px;
}
.copyDiv1,
.imageIcon1 {
  position: absolute;
  opacity: 0.4;
}
.imageIcon1 {
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.copyDiv1 {
  top: 7px;
  left: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-0);
}
.card2Div,
.card4Div {
  position: absolute;
  top: 216px;
  left: 24px;
  width: 327px;
  height: 56px;
}
.card2Div {
  top: 152px;
}
.copyDiv3 {
  position: absolute;
  top: 7px;
  left: 72px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-0);
}
.card1Div {
  position: absolute;
  top: 88px;
  left: 24px;
  width: 327px;
  height: 56px;
}
.iconKeyboardBackspace,
.topBarCloseIcon {
  position: absolute;
  top: 48px;
  left: 16px;
  width: 24px;
  height: 24px;
}
.topBarCloseIcon {
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 32px;
}
.orderViewDiv {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 742px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black);
  font-family: var(--font-cy);
}


.priceContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
  padding-bottom: 5px;
}
.priceUnderline {
  flex: 5;
  border-bottom: 1px dotted #000;
}
.regularPrice {
  font-size: var(--font-size-base);
  text-decoration: line-through;
  line-height: 16px;
  font-weight: 500;
  color: var(--red);
  text-align: right;
}
.monthlyPrice {
  font-size: var(--font-size-xl);
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-cy);
  text-align: right;
}
.durationFee {
  font-size: var(--font-size-sm);
  font-weight: 600;
  font-family: var(--font-cy);
  text-align: right;
  padding-bottom: 30px;
}


.package-building- {
  &-header {
    position: sticky;
    top: 0;
    z-index: 100;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    padding: 0 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid lightgray;

    &-icons-right-wrapper {
      display: flex;
      width: 15%;
      flex-direction: row;
      justify-content: space-between;
    }

    h2 {
      font-size: 26px;
      text-align: end;
      font-family: var(--font-cy);
    }
  }

  &-info {
    &-container {
      z-index: 2;
      background: rgb(255, 255, 255);
      box-shadow: 0 16px 16px rgba(0, 0, 0, .08);
      border-radius: 8px;
      width: auto;
      padding: 16px;
/*       @media screen and (min-width: 640px) {
        height: calc(100vh - 2px - 2px - 60px - 32px); // - outer border - inner borders - header - padding
      } */
    
      h4 {
        padding: 0;
        margin: 0;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 700;
      }

      &-add-remove-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 2rem 1rem 0 0;

        img {
          width: 28px;
          height: 28px;
          margin-left: 1.25rem;
        }
      }

      &-features-selection {
        margin-top: 1.5rem;
      }
    }

    &-step {
      display: flex;
      height: 15vh;
      width: 100%;
      margin-bottom: 1.25rem;

      &-left-side-img {
        width: 100px;
        height: 100px;
      }

      div:first-child {
        margin-right: 16px;
      }
      div:last-child {
        height: 90%;
      }
      div:last-child > p:first-child {
        margin-bottom: 16px;
      }

      &-left-side {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .08);
        padding: 12px 12px 4px 12px;
        height: 90%;
        
        p {
          padding: 0;
          margin: 0;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
        }
      }
      &-right-side {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .08);
        padding: 12px 12px 4px 12px;
        width: 70%;
        height: 90%;

        p {
          padding: 0;
          margin: 0;
          font-size: 16px;
          align-items: center;
          display: flex;
          flex-direction: row;
        }
        &-bold-text {
          font-size: 14px !important;
          font-weight: 600;
        }
        &-truck-icon {
          width: 24px;
          height: 24px;
          margin-right: .25rem;
        }
      }
    }
    &-step:not(:first-child) {
      margin-top: 16px;
    }
    &-btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid rgba(0,0,0,0);
      transition: transform 80ms ease-in-out, box-shadow 80ms ease-in-out, border 80ms ease-in-out;
    }
    &-btn:hover {
      transform: translateY(-1px);
    }
    &-btn:active {
      transform: translateY(1px);
    }
  }
}
