.baseButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--dark-blue);
  position: relative;
  top: 0;
  left: 0;
  border-radius: var(--br-sm);
  width: 78px;
  height: 32px;
}
.cmDiv {
  position: relative;
  top: 4px;
  left: 8px;
  font-size: var(--font-size-base);
  line-height: 160%;
  font-family: var(--font-montserrat);
  color: var(--white);
  text-align: left;
  display: inline-block;
}
.sizeActiveDiv {
  position: relative;
  width: 78px;
  height: 32px;
  flex-shrink: 0;
}
.rectangleButton {
  cursor: pointer;
  border: 1px solid var(--dark-blue);
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
  left: 0;
  border-radius: var(--br-sm);
  box-sizing: border-box;
  width: 79px;
  height: 32px;
}
.cmDiv1 {
  position: relative;
  top: 4px;
  left: 8px;
  font-size: var(--font-size-base);
  text-decoration: line-through;
  line-height: 160%;
  font-family: var(--font-montserrat);
  color: var(--dark-blue);
  text-align: left;
  display: inline-block;
}
.sizeNotAvailable {
  position: relative;
  width: 79px;
  height: 32px;
  flex-shrink: 0;
  opacity: 0.3;
}
.rectangleDiv {
  position: relative;
  top: 0;
  left: 0;
  border-radius: var(--br-sm);
  border: 1px solid var(--dark-blue);
  box-sizing: border-box;
  width: 78px;
  height: 32px;
  opacity: 0.3;
}
.cmDiv2 {
  position: relative;
  top: 4px;
  left: 8px;
  font-size: var(--font-size-base);
  line-height: 160%;
  font-family: var(--font-montserrat);
  color: var(--dark-blue);
  text-align: left;
  display: inline-block;
}
.sizeButton,
.sizesButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.sizeButton {
  position: relative;
  width: 78px;
  height: 32px;
  flex-shrink: 0;
}
.sizesButton {
  position: relative;
  bottom: 143px;
  left: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-md);
}
.buttonAddProduct {
  position: relative;
  width: 72px;
  height: 72px;
  display: block;
  text-align: right;
}
.buttonRemoveProduct {
  position: relative;
  width: 48px;
  height: 48px;
  text-align: center;
  padding: 6px;
  background-color: rgb(235, 235, 235);
  border-radius: 48px;
}
.descriptionDiv,
.productDescription {
  left: 0;
}
.productDescription {
  font-family: var(--font-montserrat);
  width: 100%;
  bottom: 0;
}
.descriptionDiv {
  bottom: 89px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-cy);
}
// .copyBlock1 {
//   position: relative;
//   width: calc(100% - 48px);
//   right: 24px;
//   bottom: 0;
//   left: 24px;
//   height: 111px;
//   font-family: var(--font-montserrat);
// }
.headerTitle,
.headerDescription {
  position: relative;
  line-height: 160%;
  display: inline-block;
}
.headerTitle {
  text-transform: uppercase;
  font-weight: 600;
}
.headerDescription {
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
}
.copyTitleDiv {
  position: relative;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-0);
  font-size: var(--font-size-lg);
}
.baseIcon {
  position: relative;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 100px;
}
.lineDiv1 {
  position: relative;
  background-color: var(--turquoise);
  width: 213px;
  height: 10px;
}
.lineDiv {
  position: relative;
  width: 100%;
  right: 0;
  bottom: 12px;
  left: 0;
  height: 10px;
}
.buttonDiv {
  position: relative;
  bottom: 0;
  left: 49px;
  border-radius: var(--br-sm);
  background-color: var(--white);
  border: 1px solid var(--black);
  box-sizing: border-box;
  width: 85px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: center;
}
.unionIcon {
  position: relative;
  width: 32px;
  height: 24.68px;
  opacity: 0.1;
}
.div {
  position: relative;
  bottom: 4.68px;
  left: 13px;
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
}
.activeElementDiv {
  position: relative;
  bottom: 37.32px;
  left: 72px;
  width: 32px;
  height: 24.68px;
}


.backgroundIcon {
  position: relative;
  width: 70%;
  right: -30%;
  overflow: hidden;
  max-height: 50%;
}
.baseDiv {
  width: 100%;
}
.baseDiv,
.image36Icon,
.imageDiv {
  left: 0;
  top: 0;
}
.image36Icon {
  height: 100%;
  width: calc(100% - 2.9px);
  top: 0;
  right: 2.9px;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.imageDiv {
  position: absolute;
  height: calc(100% - 88px);
  width: calc(100% - 19px);
  top: 25px;
  right: 25px;
}
.imageBlockDiv {
  position: relative;
  height: 100%;
  width: calc(100% - 40px);
  top: 0;
  right: 0;
  left: 40px;
}
.arrowBackIcon {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  z-index: 2;
}
.colorSelectionIcon {
  position: relative;
  top: calc(50% - 146px);
  left: 0;
  width: 32px;
  height: 330px;
}
.image1Icon {
  position: relative;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  object-fit: cover;
}
.imagesSliderDiv {
  position: relative;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-md);
}
.heroBlockDiv {
  position: relative;
  height: calc(100% - 354px);
  width: calc(100% - 16px);
  top: 32px;
  right: 0;
  left: 16px;
}
.productViewDiv {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: auto;
  overflow: visible;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black);
  font-family: var(--font-cy);
}


// Selectable Features
.featureContainer {
  position: relative;
  margin-left: 24px;
  margin-top: 25px;
}
.flexboxFeatureHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.featureHeader {
  height: 24px;
  text-transform: uppercase;
  font-weight: 600;
}
.iconHelp {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  opacity: 0.5;
}