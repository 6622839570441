.div {
    top: 29px;
    left: 13px;
    width: 262px;
    opacity: 0.3;
}
.inputBoxContainer {
    margin-top: 16px;
    margin-bottom: 16px;
}
.inputBoxHeader {
    line-height: 160%;
    font-size: var(--font-size-xs);
    text-transform: uppercase;
    font-weight: 600;
    font-family: var(--font-cy);
}
.inputBox {
    width: 100%;
    height: 40px;
    border-radius: var(--br-xs);
    background-color: var(--grey-light);
    border: none;
}
.inputBox:focus {
    outline: 1px solid var(--turquoise);
}

.howDoYouWantUsToContact {
    margin-top: 40px;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: var(--font-cy);
    text-align: center;
}
.base {
    position: relative;
    width: 100%;
    border-radius: var(--br-lg);
    background-color: var(--light-blue);
}
.toEnsureTheHighestQuality {
    position: relative;
    line-height: 160%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.greatChoice {
    position: relative;
    padding-top: 24px;
    width: 100%;
    font-size: var(--font-size-xl);
    line-height: 160%;
    font-weight: 600;
    font-family: var(--font-cy);
    color: var(--lemon);
    display: inline-block;
}
.bannerSection {
    position: relative;
    padding-left: 16px;
    width: calc(100% - 32px);
    padding-top: 59px;
    // height: auto;
    text-align: center;
    color: var(--white);
}
.contactMethodSection {
    position: relative;
    margin-left: 24px;
}
.inputSection {
    position: relative;
    margin: 24px;
}
.arrowBackIcon {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 24px;
    height: 24px;
    z-index: 2;
}
.errorMessage {
    position: relative;
    width: calc(100% - 48px);
    margin-top: 24px;
    margin-bottom: 12px;
    line-height: 160%;
    color: var(--red);
    display: flex;
    align-items: center;
}
.submitButton {
    line-height: 160%;
    text-transform: uppercase;
    font-weight: 600;
}
.rocketIcon {
    width: 40px;
    margin-right: 10px;
}
.button {
    position: relative;
    cursor: pointer;
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 32px;
    height: 48px;
    text-align: center;
    font-family: var(--font-cy);
    background-color: var(--lemon);
    border: none;
}
.inputFilled2 {
    position: absolute;
    width: calc(100% - 48px);
    top: 422px;
    right: 24px;
    left: 24px;
    height: 60px;
}
.inputFilled2 {
    top: 498px;
}
.contactDetailsContainer {
    position: relative;
    background-color: var(--white);
    width: 100%;
    overflow-y: visible;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--black);
    font-family: var(--font-montserrat);
}
.radioButtonContainer {
    padding-left: 8px;
}
.durationFee {
    font-size: var(--font-size-sm);
    font-weight: 600;
    font-family: var(--font-cy);
    text-align: right;
}