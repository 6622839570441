.baseDiv {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-sm);
  background-color: var(--light-blue);
  height: 48px;
}
.backToWebsite {
  position: relative;
  top: 20%;
  font-size: var(--font-size-base);
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-cy);
  color: var(--white);
  text-align: center;
  display: inline-block;
}

.bottomContainer {
  position: absolute;
  width: calc(100% - 32px);
  margin: 16px;
  bottom: 16px;
  text-align: center;
}
.button {
  position: relative;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  width: calc(100% - 16px);
  height: 48px;
}
.lemonIcon {
  width: 99.67px;
  height: 99.67px;
  text-align: center;
  padding-top: 20px;
}
.successHeader {
  font-size: var(--font-size-xl);
  line-height: 160%;
  font-weight: 600;
  text-align: center;
  display: inline-block;
}

.weAreCheckingYourRequestA {
  font-family: var(--font-montserrat);
}
.weAreCheckingYourRequestA,
.yourSaltyLemonTeam {
  line-height: 160%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yourSaltyLemonTeam {
  padding-top: 100px;
  text-transform: uppercase;
  font-weight: 600;
}
.goBackLemonShake {
  font-size: var(--font-size-sm);
  line-height: 160%;
  font-family: var(--font-montserrat);
  display: inline-block;
  text-align: center;
  padding-bottom: 30px;
}
.successScreenDiv {
  position: relative;
  background-color: var(--lemon);
  width: 100%;
  @media screen and (min-width: 640px) {
    height: calc(100vh - 2px); // - outer border
  }
  @media screen and (max-width: 640px) {
    height: 100vh;
  }
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--black);
  font-family: var(--font-cy);
}
.centeredContent {
  position: relative;
  top: 20%;
  text-align: center;
  vertical-align: middle;
  margin-left: 16px;
  margin-right: 16px;
}