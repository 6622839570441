.backgroundDiv {
  position: absolute;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  border-radius: var(--br-xs);
  background-color: var(--grey-light);
}
.baseDiv {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  border-radius: var(--br-md);
  background-color: var(--lemon);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  height: 128px;
}
.iconNavigateNext {
  position: absolute; 
  top: 50%; 
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}
.typeName {
  position: absolute; 
  top: 50%; 
  transform: translateY(-50%);
  right: 40px;
  text-transform: uppercase;
  font-weight: 600;
}
.nextTypeContainer {
  position: absolute;
  top: 88px;
  right: 12px;
  width: 65px;
  height: 29px;
  display: inline-block;
}
.baseDiv1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-md);
  background-color: var(--dark-blue);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}
.baseDiv2 {
  position: absolute;
  height: 99.05%;
  width: 100%;
  top: 0.95%;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-md);
  background-color: var(--light-blue);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}
.illustrationIcon {
  position: absolute;
  top: 0;
  left: calc(50% - 102.23px);
  width: 124.45px;
  height: 124.45px;
}

.whatWouldYouLikeToChoose {
  position: absolute;
  width: 63.73%;
  top: 87px;
  left: 18.13%;
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chooseType {
  position: relative;
  background-color: var(--white);
  width: 100%;
  @media screen and (min-width: 640px) {
    height: calc(100vh - 2px); // - border
  }
  @media screen and (max-width: 640px) {
    height: 100vh;
  }
  text-align: right;
  font-size: var(--font-size-lg);
  color: var(--black);
  font-family: var(--font-cy);
}
.chooseType1 {
  position: absolute;
  cursor: pointer;
  width: calc(100% - 111px);
  top: 186px;
  right: 55px;
  left: 56px;
  height: 128px;
  border: 0px;
}
.chooseType2 {
  position: absolute;
  cursor: pointer;
  width: calc(100% - 111px);
  top: 330px;
  right: 55px;
  left: 56px;
  height: 128px;
  color: var(--turquoise);
  border: 0px;
}
.chooseType3 {
  position: absolute;
  cursor: pointer;
  width: calc(100% - 111px);
  top: 474px;
  right: 55px;
  left: 56px;
  height: 129.23px;
  color: var(--lemon);
  border: 0px;
}
