.draggable-slider-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-family: var(--font-cy);
}
.slider-SVG {
  width: 100%;
  height: 10vh;
  overflow: hidden;
}
.value {
  fill: #000;
  font-size: 5rem;
  font-weight: bold;
  text-anchor: middle;
  pointer-events: none;
}