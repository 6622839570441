.centered {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.buttonRemoveProduct {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 26px;
  text-align: center;
  background-color: rgb(235, 235, 235);
  margin: 5px;
  padding: 3px;
  border-radius: 5px;
}

.product-carousel- {
  &-container {
    padding: 0rem 0rem 1rem 0rem;
    margin: 1rem 0rem 1rem 0rem;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 3rem;
  
      h4 {
        padding: 0;
        margin: 0 !important;
        font-size: 18px;
        font-weight: 700;
      }
    
      img {
        transition: .25s cubic-bezier(1, 0, 0, 1);
      }
    }
  }

  &-swipable-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    height: auto;
    margin: auto;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    animation: fadeIn 160ms ease-in-out forwards;
  }

  &-add-item {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 0.75rem;
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px solid rgba(210, 210, 210, 0.8);
      border-radius: 12px;
      align-items: center;
      height: 12rem;
      width: 7rem;
      box-shadow: 3px 3px 5px rgba(230, 230, 230, 0.8);
      background-color: rgba(255, 255, 255, 1);

      p {
        margin: 0;
        font-size: 38px;
        font-weight: 300;
        color: rgba(20, 20, 20, 0.8);
      }
    }
  }

  &-item {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 0.75rem;
    }
    &-content {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 2px solid rgba(210, 210, 210, 0.8);
      border-radius: 12px;
      align-items: center;
      height: 12rem;
      width: 7rem;
      box-shadow: 3px 3px 5px rgba(230, 230, 230, 0.8);
      background-color: rgba(255, 255, 255, 1);

      img {
        height: 65%;
        width: 100%;
      }
    }
    &-name {
      &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 2.25rem;
        border-top: 1px solid rgba(210, 210, 210, 0.8);
        border-bottom: 1px solid rgba(210, 210, 210, 0.8);
        padding: .25rem 0;
        white-space: pre-wrap;
      }
      &-text {
        font-family: var(--font-cy);
        text-align: center;
        width: 90%;
        font-size: 12px;
        font-weight: 700;
        margin: 0;
      }
    }
    &-size-and-price-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 2.25rem;
    }
    &-size-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      white-space: pre-wrap;
      border-right: 1px solid rgba(210, 210, 210, 0.8);
      
      p {
        margin: 0;
        font-size: 12px;
        width: 100%;
        padding: .25rem;
        word-break: break-all;
        font-weight: 500;
        text-align: center;
      }
    }
    &-price-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 50%;
      
      p {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        
        span {
          font-weight: 400;
          font-size: 10px;
        }
      }
    }
  }

  &-empty-item {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 0 .5rem;

      h5 {
        font-size: 18px;
        margin: 1rem 0;
        color: #000;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 2px solid rgba(210, 210, 210, 0.8);
      border-radius: 12px;
      align-items: center;
      height: 12rem;
      width: 7rem;
      box-shadow: 3px 3px 5px rgba(230, 230, 230, 0.8);
      background-color: rgba(255, 255, 255, 1);

      &-above {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;

        p {
          margin: 0;
          font-size: 38px;
          font-weight: 300;
          color: rgba(20, 20, 20, 0.8);
        }
      }

      &-middle-row {
        &-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-top: 1px solid rgba(210, 210, 210, 0.8);
          border-bottom: 1px solid rgba(210, 210, 210, 0.8);
          padding: .25rem 0;
        }
        &-text {
          font-size: 12px;
          font-weight: 700;
          margin: 0;
        }
      }
    }
    &-size-and-price-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 20%;
    }
    &-size-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      border-right: 1px solid rgba(210, 210, 210, 0.8);
      
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
    }
    &-price-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 50%;
      
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        
        span {
          font-weight: 400;
          font-size: 8px;
        }
      }
    }
  }

  &-chevron-icon {
    width: 36px;
    height: 36px;
  }

  // Modal
  &-add-item-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 98%;
    height: 15rem;
    white-space: pre-wrap;
    background-color: white;
    border: 1px solid black;
    border-radius: 15px;
    padding: 0 0 4rem 0;

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    h1 {
      text-align: center;
      width: 70%;
    }

    p {
      margin: .25rem 0;
      width: 65%;
      padding: .5rem 1rem;
      border-radius: 10px;
    }

    button {
      border: none;
      font-size: 18px;
      font-weight: 800;
      width: 16.5vw;
      height: 5vh;
      margin-top: 1.25rem;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 32px;
      transition: transform 120ms ease-in-out, box-shadow 120ms ease-in-out;
      cursor: pointer;
      box-shadow: 0 1px 2px rgba(112, 171, 169, 0.32);  

      @media only screen and (max-width: 668px) {
        width: 32vw;
      }
    }

    &-submit-area {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 74%;
      height: 5rem;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}