.selectableOptionsContainer {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.selectableOptionOuter {
  position: relative;
  border-radius: var(--br-sm);
  border: 1px solid var(--dark-blue);
  box-sizing: border-box;
  margin: 3px;
}

.selectableOptionInner {
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
  text-align: left;
  display: inline-block;
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
}


.selectable-feature- {
  &-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90vw;
    padding: 0rem;
  }
  &-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90vw;
    overflow-x: scroll;
    padding: 0rem 0 .25rem 0;

    &-color-option-wrapper {
      width: 2.15rem;
      height: 2.15rem;
      padding: .25rem;
      margin: 0rem .25rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
  
      p {
        margin: 0;
      }
    }
  }
  &-row::-webkit-scrollbar {
    display: none;
  }
}