.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
.arrowBackIcon {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  z-index: 2;
}

.package-building- {
  &-info {
    &-container {
      z-index: 2;
      background: rgb(255, 255, 255);
      box-shadow: 0 16px 16px rgba(0, 0, 0, .08);
      border-radius: 8px;
      width: auto;
      padding: 16px;
      padding-top: 20px;
      @media screen and (min-width: 640px) {
        height: calc(100vh - 2px - 2px - 96px - 40px - 16px); // - outer border - inner border - footer height - paddingTop - paddingBottom
      }
      @media screen and (max-width: 640px) {
        height: calc(100vh - 2px - 96px - 40px - 16px); // - inner border - footer height - paddingTop - paddingBottom
      }

      h4 {
        padding: 0;
        margin: 0;
        margin-bottom: 16px;
        font-size: 22px;
        font-weight: 700;
        text-align: center;
      }

      &-add-remove-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 2rem 1rem 0 0;

        img {
          width: 28px;
          height: 28px;
          margin-left: 1.25rem;
        }
      }

      &-features-selection {
        margin-top: 1.5rem;
      }

      &-package-price {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 2rem 0;
        padding: 1.5rem 0rem 3rem 0rem;
        border-bottom: 1px solid rgba(210, 210, 210, 0.8);

        h3 {
          margin: 0;
          font-size: 24px;
          color: rgb(41, 137, 6);
        }
      }
    }

    &-step {
      display: flex;
      height: 15vh;
      width: 100%;
      margin-bottom: 1.25rem;

      &-left-side-img {
        width: 100px;
        height: 100px;
      }

      div:first-child {
        margin-right: 16px;
      }
      div:last-child {
        height: 90%;
      }
      div:last-child > p:first-child {
        margin-bottom: 16px;
      }

      &-left-side {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .08);
        padding: 12px 12px 4px 12px;
        height: 90%;
        
        p {
          padding: 0;
          margin: 0;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
        }
      }
      &-right-side {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .08);
        padding: 12px 12px 4px 12px;
        width: 70%;
        height: 90%;

        p {
          padding: 0;
          margin: 0;
          font-size: 16px;
          align-items: center;
          display: flex;
          flex-direction: row;
        }
        &-bold-text {
          font-size: 14px !important;
          font-weight: 600;
        }
        &-truck-icon {
          width: 24px;
          height: 24px;
          margin-right: .25rem;
        }
      }
    }
    &-step:not(:first-child) {
      margin-top: 16px;
    }
    &-btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid rgba(0,0,0,0);
      transition: transform 80ms ease-in-out, box-shadow 80ms ease-in-out, border 80ms ease-in-out;
    }
    &-btn:hover {
      transform: translateY(-1px);
    }
    &-btn:active {
      transform: translateY(1px);
    }
  }
}
