.backgroundDiv {
  position: absolute;
  width: calc(100% - 16px);
  right: 8px;
  bottom: 8px;
  left: 8px;
  border-radius: var(--br-xs);
  background-color: var(--color-gray-100);
  height: 337px;
}
.baseDiv {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  border-radius: var(--br-sm);
  background-color: var(--lemon);
  height: 48px;
}
.getStartedDiv {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-cy);
  color: var(--black);
  text-align: center;
  display: inline-block;
}
.buttonPrimary {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  width: calc(100% - 64px);
  right: 32px;
  bottom: 24px;
  left: 32px;
  height: 48px;
}
.rectangleDiv,
.rectangleDiv1 {
  bottom: 0;
  border-radius: var(--br-lg);
  background-color: var(--blue);
}
.rectangleDiv {
  position: absolute;
  left: calc(50% + 4px);
  width: 80px;
  height: 8px;
}
.rectangleDiv1 {
  left: calc(50% - 84px);
  width: 40px;
  opacity: 0.3;
}
.rectangleDiv1,
.rectangleDiv2,
.sliderDiv {
  position: absolute;
  height: 8px;
}
.rectangleDiv2 {
  bottom: 0;
  left: calc(50% - 40px);
  border-radius: var(--br-lg);
  background-color: var(--blue);
  width: 40px;
  opacity: 0.3;
}
.sliderDiv {
  bottom: 136px;
  left: calc(50% - 84.5px);
  width: 168px;
}
.enjoyKitingDiv {
  position: absolute;
  bottom: 275px;
  left: calc(50% - 88.5px);
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
}
.getTheGearShippedToYourD {
  position: absolute;
  bottom: 234px;
  left: calc(50% - 148.5px);
  font-size: var(--font-size-base);
  line-height: 160%;
  font-family: var(--font-montserrat);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 294px;
}
.maskGroupIcon {
  position: absolute;
  height: calc(100% - 353px);
  width: calc(100% - 16px);
  top: 8px;
  right: 8px;
  bottom: 345px;
  left: 8px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.howItWorksStep3 {
  position: relative;
  background-color: var(--white);
  width: 100%;
  @media screen and (min-width: 640px) {
    height: calc(100vh - 2px); // - outer border
  }
  @media screen and (max-width: 640px) {
    height: 100vh;
  }
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--black);
  font-family: var(--font-cy);
}
