@media screen and (min-width: 640px) {
    .bottomBarDiv {
      position: sticky;
    }
}
  
@media screen and (max-width: 640px) {
    .bottomBarDiv {
      position: fixed;
    }
}
  
.bottomBarDiv {
    width: 100%;
    bottom: 0;
    height: 96px;
    text-align: center;
    font-size: var(--font-size-sm);
    border-top: 1px solid lightgray;
    background-color: white;
}