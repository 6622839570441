.scrollWrapper {
  overflow-x: scroll;
}

.swipable-row-{
  &-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding-top: 30px;
    // padding-bottom: 30px;
    white-space: nowrap;
    width: auto;
    margin: auto;
  }
  &-main::-webkit-scrollbar {
    display: none;
  }

  &-spacer {
    min-width: 5.5%;
    height: 80%;
    margin: auto;
  }

  &-item {
    display: inline-block;
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-right: 20px;

      h5 {
        font-size: 20px;
        height: 2rem;
        margin: 1rem 0;
        color: #000;
        overflow-wrap: break-word;
      }
    }
    &-content {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 2px solid #4BE3DE;
      border-radius: 12px;
      align-items: center;
      box-shadow: 3px 3px 5px rgba(230, 230, 230, 0.8);
      // Remove after dynamic data
      background-color: rgb(245, 245, 245);

      height: 16rem;
      width: 16rem;
      img {
        height: 16rem;
        width: 16rem;
      }
    }
  }
  &-custom-item {
    display: inline-block;
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: auto;

      h5 {
        font-size: 22px;
        margin: 1rem 0;
        color: #000;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px solid #e9e9e9;
      border-radius: 12px;
      align-items: center;
      height: 15rem;
      width: 12rem;
      box-shadow: 3px 3px 5px rgba(230, 230, 230, 0.8);
      // Remove after dynamic data
      background-color: rgb(255, 255, 255);

      p {
        margin: 0;
        font-size: 38px;
        font-weight: 300;
        color: rgba(20, 20, 20, 0.8);
      }
    }
  }
}