@media screen and (min-width: 640px) {
  .container {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-width: 600px;
    height: calc(100vh - 2px);
    border: 1px solid rgba(230, 230, 230, 0.1);
    overflow: scroll;
    background: rgb(245, 245, 245);
    border-radius: 8px;
    opacity: 1;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .container::-webkit-scrollbar {
    display: none;
  }
  
}


body {
  margin: 0;
  line-height: normal;
  font-family: var(--font-montserrat), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  width: 100vw;
  line-height: normal;
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  font-family: var(--font-montserrat), sans-serif;
  overflow-x: hidden !important;
  width: 100vw;

  /* fonts */
  --font-montserrat: montserrat;
  --font-cy: cy;

  /* font sizes */
  --font-size-xs: 12px;
  --font-size-sm: 14px; // originally: 12px;
  --font-size-base: 16px; // originally: 14px;
  --font-size-lg: 20px; // originally: 18px;
  --font-size-xl: 26px; // originally: 24px;

  /* Colors */
  --white: #fff;
  --grey-light: #f6f6f6;
  --grey: #cecece;
  --light-blue: #2C7695;
  --blue: #214d72;
  --dark-blue: #1D3752;
  --lemon: #f9ff00;
  --black: #000;
  --red: #ed5861;
  --turquoise: #4ce4de;

  /* Gaps */
  --gap-0: 0px;
  --gap-md: 8px;

  /* Paddings */
  --padding-sm: 8px;
  --padding-md: 13px;
  --padding-lg: 17px;

  /* border radiuses */
  --br-xs: 2px;
  --br-sm: 4px;
  --br-md: 8px;
  --br-lg: 10px;
  --br-xl: 100px;
}


/* Typography */
.calc, .calc > * {
  font-family: var(--font-montserrat), sans-serif;
}

.calc p {
  padding: 0;
  margin: 0;
}

.calc__h2 {
  font-size: 24px;
  font-weight: 700;
}

.calc__h3 {
  font-size: 20px;
  font-weight: 700;
}

.calc__h4 {
  font-size: 16px;
  font-weight: 700;
}

.calc__body--sm {
  font-size: 14px;
  font-weight: 400;
}
.calc__body--md {
  font-size: 16px;
  font-weight: 400;
}

.calc__bold--700 {
  font-weight: 700;
}
.calc__bold--600 {
  font-weight: 600;
}
.calc__bold--500 {
  font-weight: 500;
}
.calc__bold--400 {
  font-weight: 400;
}